import React, { Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";

const CurrentDate = (props) => {
  const { lastUpdated, timeFormat12Hour } = props;

  let lastUpdateDateIso = moment(lastUpdated).tz("America/New_York").format();
  let lastUpdateDateHuman = moment(lastUpdated)
    .tz("America/New_York")
    .format(timeFormat12Hour ? "ddd MMM Do hh:mm:ss A" : "ddd MMM Do H:mm:ss");

  return (
    <Fragment>
      Last Update:{" "}
      <time dateTime={lastUpdateDateIso}>{lastUpdateDateHuman} EST </time>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    lastUpdated: state.arrivalsBoard.lastUpdated,
  };
};

export default connect(mapStateToProps)(CurrentDate);
